.page-block {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(83, 63, 105, 0.05);
  border-radius: 6px;
  margin-top: 20px;

  &__header {
    padding: 15px 25px;
    border-bottom: 1px solid #ebedf3;
    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
  }

  &__body {
    padding: 15px 25px;
  }

  &__bottom {
    padding: 5px 25px 15px;
  }
}
