$sidebarBg: #1e1e2d;
$sidebarBgActive: #1a1a27;
$fontColor: #a2a3b7;
$activeStroke: #3699ff;
$stroke: #494b74;

.sidebar {
  width: 265px;
  background: $sidebarBg;
  overflow: hidden;
  &--closed {
    width: 60px;

    .sidebar__header {
      justify-content: center;
    }

    .sidebar__close {
      transform: rotate(180deg);
    }
    .sidebar__link {
      display: none;
    }
    .sidebar__menu-label {
      display: none;
    }
    .sidebar__item-name {
      display: none;
    }
    .sidebar__item {
      a,
      button {
        padding: 5px 20px;
        align-items: center;
      }

      svg {
        margin: 0;
      }
    }
  }

  &__header {
    background: $sidebarBgActive;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 14px 20px;
  }

  &__link {
    color: white;
    font-size: 24px;
    line-height: 28px;
  }

  &__close {
    background: transparent;
    border: none;
  }

  &__body {
    padding-top: 15px;
  }

  &__menu {
  }

  &__submenu {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__menu-label {
    margin-bottom: 0;
    padding: 5px 25px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 11px;
    text-transform: uppercase;
    color: $fontColor;
    letter-spacing: 0.3px;
  }

  &__submenu-list {
  }

  &__item {
    display: flex;
    align-items: center;
    color: $fontColor;

    &--active,
    &:hover,
    &:focus {
      transition: all 0.3s;
      color: white;
      background: $sidebarBgActive;
    }

    a,
    button {
      background: transparent;
      border: none;
      width: 100%;
      height: 100%;
      padding: 10px 25px;
      display: flex;
      align-items: center;
      color: inherit;
      font-size: 13px;
    }

    svg {
      margin-right: 10px;
      path {
        transition: all 0.3s;
      }
    }
  }

  &__item-name {
    margin-bottom: 0;
    font-size: 13px;
  }
}
