.categories {
  &__table {
    table {
      width: 100%;
    }

    td:last-child,
    th:last-child {
      width: 250px;
    }

    th,
    td {
      padding: 10px;
      border-bottom: 1px solid #ebedf3;
    }

    th {
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      text-transform: uppercase;
      color: #7e8299;
    }
  }
}
