.avatar {
  &__name {
    color: #7e8299;
    font-size: 13px;
    font-weight: 700;
  }

  display: inline-block;
  background-color: #c9f7f5;
  color: #1bc5bd;
  border-radius: 0.25rem;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  font-size: 16px;
}
