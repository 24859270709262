.last-items {
  overflow: auto;
  max-width: calc(100vw - 265px - 50px);

  &__close {
    position: absolute;
    background: transparent;
    border: none;
    color: #f64e60;
    font-size: 20px;
    top: 0px;
    right: 5px;
  }

  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    position: relative;
    min-width: 180px;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    box-shadow: 0px 0px 30px rgba(83, 63, 105, 0.05);
    border-radius: 6px;
    margin-right: 15px;

    &--incomes {
      background: #c9f7f5;
      .last-items__sum {
        color: #1bc5bd;
      }
    }

    &--spend {
      background: #ffe2e5;
      .last-items__sum {
        color: #f64e60;
      }
    }
    &--accumulation {
      background: #eee5ff;
      .last-items__sum {
        color: #8950fc;
      }
    }
  }
  &__icon {
    margin-right: 10px;
    svg {
      width: 50px;
      height: 50px;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
  }
  &__label {
    margin-bottom: 5px;
    color: #3f4254;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  &__sum {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  &__date {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #b5b5c3;
  }
}
