.auth {
  min-height: 100vh;
  width: 100%;
  background-color: white;

  &__inner {
    max-width: 500px;
    padding: 20px 50px;
    text-align: center;

    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }
  &__line {
    border: 1px dashed #eeeeee;
    position: absolute;
    top: 50%;
    width: calc(50% - 30px);

    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
  &__or {
    margin-bottom: 0;
  }

  &__lines {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__input {
    width: 400px;
    margin-bottom: 20px;
    min-height: 50px;

    @media (max-width: 768px) {
      width: 300px;
    }
  }

  &__btn {
    width: 400px;
    min-height: 50px;

    @media (max-width: 768px) {
      width: 300px;
    }
  }
}
