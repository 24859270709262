@keyframes ldio-uf2l8kt5zyh {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-uf2l8kt5zyh div {
  position: absolute;
  width: 120px;
  height: 120px;
  border: 20px solid #3699ff;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-uf2l8kt5zyh div {
  animation: ldio-uf2l8kt5zyh 1s linear infinite;
  top: 100px;
  left: 100px;
}
.loadingio-spinner-rolling-382cdap6rlp {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-uf2l8kt5zyh {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-uf2l8kt5zyh div {
  box-sizing: content-box;
}
