$primary: #e1f0ff;
$secondary: #8950fc;
$danger: #f64e60;
$font: "Open Sans", sans-serif;

@import "~bootstrap/scss/bootstrap.scss";
@import "../feature/auth/screen/LoginPage/styles.scss";
@import "./sidebar.scss";
@import "./arrow.scss";
@import "./header.scss";
@import "./avatar.scss";
@import "./last-item.scss";
@import "./categories.scss";
@import "./page-block.scss";

a {
  color: #3699ff;
}

.btn-primary {
  color: #3699ff;
  &:hover,
  &:focus {
    color: darken(#3699ff, 10);
  }
}

.btn-danger {
  color: white;
  &:hover,
  &:focus {
    color: white;
  }
}

.badge-danger {
  background-color: $danger;
}
.badge-success {
  background-color: #1bc5bd;
}
.badge-secondary {
  background-color: $secondary;
}

body {
  background-color: #b5b5c3;
  font-family: $font;
}

a {
  text-decoration: none;
}
.page {
  min-height: 100vh;
  width: 100%;

  &__body {
    min-height: 100vh;
    width: 100%;
  }

  &__body-main {
    padding: 15px 25px;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}

.messages {
  position: fixed;
  bottom: 0;
  right: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  z-index: 1100;
}

.sidebar__burger {
  display: none;
  border: 2px solid #b5b5c3;
}

@media (max-width: 767px) {
  .sidebar {
    position: fixed;

    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    transition: all 0.3s ease;
    max-height: 100vh;
    overflow: auto;
    min-height: 100vh;

    min-height: -webkit-fill-available;
    .sidebar__close {
      display: none;
    }
  }

  .page__sidebar {
    &.active {
      .sidebar {
        transform: translateX(0);
      }
    }
  }

  .last-items {
    width: 100%;
    max-width: 100%;
  }
  .page__body-main {
    padding: 15px 10px;
  }

  .page-block__body {
    width: 100%;
    overflow: auto;
  }
  .sidebar__burger {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10000;

    span {
      height: 3px;
      width: 50%;
      position: absolute;
      background-color: black;
      left: 25%;
      transition: all 0.4s ease-in-out;

      &:first-child {
        top: 15px;
      }
      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-child {
        bottom: 15px;
      }
    }

    &.active {
      span {
        &:first-child {
          transform: translateY(6.5px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          transform: translateY(-6.5px) rotate(-45deg);
        }
      }
    }
  }
  .history {
    .row {
      text-align: center;

      time {
        order: 1;
        flex: 100% 0 1;
      }
    }
  }

  .header__spend {
    display: flex;
    flex-direction: column;
  }

  .header {
    padding: 0 15px;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }
}
