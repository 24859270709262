.header {
  background-color: #fff;
  padding: 10px 25px;

  &__inner {
  }

  &__spend {
    font-size: 14px;
  }
}
