.arrow {
  animation: blink 1s ease infinite;

  &:first-child {
    animation-delay: 0.2s;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
